<script>
	import {EventBus} from '../event-bus';

	export default {

		methods: {
			clicked()
			{
				EventBus.$emit('bot-button-clicked');
			}
		}
	}
</script>

<template>
	<a href="" v-on:click.stop.prevent="clicked()"><img class="icon" src="/img/icon-bot-white.svg" alt="Bot">BotBrasse</a>
</template>