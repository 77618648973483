module.exports = {
	init: function()
	{
		var $ = require('jquery');
		var $link = $('.js-delete-timedeviation');

		$link.click(function(e)
		{
			e.preventDefault();
			e.stopPropagation();

			if ( ! confirm('Säker?') )
			{
				return;
			}

			var deleteUrl = $(this).data('delete-url');
			var token = $(this).data('token');

			$.ajax({
				url: deleteUrl,
				type: 'post',
				data: {
					_method: 'delete',
					_token: token
				},
				success: function(data)
				{
					window.location = data.redirectTo;
				}
			});
		})
	}
};