<script>
	import _ from 'underscore';
	import {EventBus} from '../../event-bus';
	import VueScrollTo from 'vue-scrollto';
	import Currency from '../helpers/currency';

	export default {
		name: 'edit-project-expenses',

		props: [
			'currentexpenses',
			'inputname',
			'availableMonths',
		],

		data: function() {
			return {
				expenses: [],
				defaultAvailableMonths: [],
				inputNumber: 0,
			}
		},

		created: function() {
			this.expenses = JSON.parse(this.currentexpenses);
			this.defaultAvailableMonths = JSON.parse(this.availableMonths);
		},

		mounted: function() {
			let _this = this;

			EventBus.$on('show-add-project-expenses-ui', function() {
				_this.inputNumber++;

				_this.expenses.push({
					amount: '',
					comment: '',
					year_month: (new Date()).getFullYear() + '-' + ((new Date()).getMonth() + 1),
					available_months: _this.defaultAvailableMonths,
					attachment_fileinput: 'expense_attachment_' + _this.inputNumber,
				});

				// setTimeout(function() {
				// 	VueScrollTo.scrollTo(_this.$refs.addexpense);
				// 	_this.$refs.amount.focus();
				// }, 10);
			});
		},

		methods: {
			remove: function(expenseToRemove) {
				if ( confirm('Säker?') )
				{
					this.expenses = _.filter(this.expenses, function(expense) {
						return expenseToRemove !== expense;
					});
				}
			},

			formatAmount: function(amount) {
				return Currency.format(amount);
			},
		},

		watch: {},
	}
</script>

<template>
	<div class="project-data-row">
		<input type="hidden" :name="inputname" :value="JSON.stringify(expenses)">

		<h3 class="title-xs">Externa inköp</h3>
			<div class="flex">
				<div class="project-data-cell no-border"><p class="title-xxs">Månad</p></div>
				<div class="project-data-cell no-border"><p class="title-xxs">Belopp</p></div>
				<div class="project-data-cell no-border"><p class="title-xxs">Kommentar</p></div>
				<div class="project-data-cell no-border"><p class="title-xxs">Bilaga</p></div>
				<div class="project-data-cell no-border"><p class="title-xxs">Inkluderad i priset?</p></div>
				<div class="project-data-cell no-border"><p class="title-xxs">Ta bort</p></div>
			</div>
		<div v-for="expense in expenses">

			<div class="flex" v-if="! expense.status || expense.status != 'invoiced'">
				<div class="project-data-cell no-border">
					<select v-model="expense.year_month">
						<option v-for="(monthString, month) in expense.available_months" v-bind:value="month">{{ monthString }}</option>
					</select>
				</div>

				<div class="project-data-cell no-border" >
					<input type="number" v-model="expense.amount" placeholder="Belopp">
				</div>

				<div class="project-data-cell no-border">
					<input type="text" v-model="expense.comment" placeholder="Kommentar">
				</div>

				<div class="project-data-cell no-border">
					<a class="padded-link" v-if="! expense.attachment_fileinput && expense.attachment_name" :href="expense.attachment_url" target="_blank">{{ expense.attachment_name }}</a>
					<span class="padded-link" v-if="! expense.attachment_fileinput && ! expense.attachment_name">(Ingen bilaga)</span>
					<input v-if="expense.attachment_fileinput" type="file" :name="expense.attachment_fileinput">
				</div>

				<div class="project-data-cell no-border">
					<label><input type="checkbox" v-model="expense.included"> Inkluderad</label>
				</div>

				<div class="project-data-cell no-border">
					<button class="member-remove" v-on:click.prevent="remove(expense)">-</button>
				</div>
			</div>

			<div class="flex" v-if="expense.status == 'invoiced'">
				<div class="project-data-cell no-border">
					{{ expense.year_month_string }}
				</div>

				<div class="project-data-cell no-border" >
					{{ formatAmount(expense.amount) }}
				</div>

				<div class="project-data-cell no-border">
					{{ expense.comment }}
				</div>

				<div class="project-data-cell no-border">
					<a v-if="expense.attachment_name" :href="expense.attachment_url" target="_blank">{{ expense.attachment_name }}</a>
					<span v-if="! expense.attachment_name">(Ingen bilaga)</span>
				</div>

				<div class="project-data-cell no-border">
					<span v-if="expense.included">Ingår i priset</span>
				</div>

				<div class="project-data-cell no-border">
					(Fakturerad, kan ej redigeras)
				</div>
			</div>

		</div>

		<div class="flex" v-if="expenses.length == 0">
			Här var det tomt!
		</div>
	</div>
</template>