<script>
	import Fuse from 'fuse.js';
	import _ from 'underscore';

	export default {

		props: [
			'jsonurl',
			'createurl',
			'customer',
			'csrftoken'
		],

		data: function() {
			return {
				selectedCustomer: null,
				showAddView: false,
				customerSearchTerm: '',
				customers: null,
				matchingCustomers: [],

				newCustomer: {
					valid: false,
					name: '',
					organisationNumber: '',
					reference: '',
					address: '',
				},

				validationErrors: ''
			}
		},

		created: function() {
			this.selectedCustomer = this.customer;
		},

		methods: {
			updateMatchingCustomers: function() {
				if ( !(this.customers instanceof Fuse) )
				{
					return;
				}

				this.matchingCustomers = this.customers.search(this.customerSearchTerm).slice(0, 10);
			},

			validateCustomer()
			{
				let valid = true;

				if ( this.newCustomer.name.replace(/ /g, '').length === 0 )
				{
					valid = false;
				}
				if ( this.newCustomer.organisationNumber.replace(/ /g, '').length !== 0 && !this.newCustomer.organisationNumber.replace(/ /g, '').match(/^[0-9]{6}-[0-9]{4}$/) )
				{
					valid = false;
				}
				if ( this.newCustomer.reference.replace(/ /g, '').length === 0 )
				{
					valid = false;
				}
				if ( this.newCustomer.address.replace(/ /g, '').length === 0 )
				{
					valid = false;
				}

				this.newCustomer.valid = valid;
			},

			resetAddCustomer()
			{
				this.showAddView = false;

				this.newCustomer.valid = false;
				this.newCustomer.name = '';
				this.newCustomer.organisationNumber = '';
				this.newCustomer.reference = '';
				this.newCustomer.address = '';
			},

			saveCustomer()
			{
				if ( !this.newCustomer.valid )
				{
					return;
				}

				let _this = this;

				let data = {
					_token: this.csrftoken,
					name: this.newCustomer.name,
					organisation_number: this.newCustomer.organisationNumber,
					reference: this.newCustomer.reference,
					address: this.newCustomer.address
				};

				this.validationErrors = [];

				this.$http.post(this.createurl, data).then(response => {
					console.debug('Customer saved.');
					this.selectedCustomer = response.body;
					this.customers = null;
					this.showAddView = false;
					this.resetAddCustomer();
				}, response => {
					if ( response.status === 422 )
					{
						let messages = [];

						_.each(response.body, function(item) {
							messages.push(item[0].charAt(0).toUpperCase() + item[0].slice(1));
						});

						this.validationErrors = messages;

						this.timer = setTimeout(function() {
							_this.validationErrors = [];
						}, 5000);
					}
					else
					{
						this.validationErrors = ['Något gick fel. Kunde inte spara.'];

						this.timer = setTimeout(function() {
							_this.validationErrors = [];
						}, 5000);

						console.error('Error: Could not save customer.');
					}
				});
			}
		},

		watch: {
			selectedCustomer()
			{
				this.$emit('customer-selected', this.selectedCustomer);
			},
			customerSearchTerm()
			{
				if ( this.customers === null )
				{
					this.customers = 'wait';

					this.$http.get(this.jsonurl).then(response => {
						console.debug('Customers loaded.');

						this.customers = new Fuse(response.body, {
							threshold: 0.1,
							matchAllTokens: true,
							tokenize: true,
							keys: ['name']
						});

						this.updateMatchingCustomers();
					}, response => {
						console.error('Error: Could not load customers.');
						this.customers = null;
					});
				}

				this.updateMatchingCustomers();
			},

			newCustomer: {
				handler: function() {
					this.validateCustomer();
				},
				deep: true
			}
		},
	}
</script>

<template>
	<div>
		<div class="select-customer" v-if="! showAddView">
			<h3 class="title-s">Välj kund</h3>
			<div v-if="selectedCustomer !== null" id="selected-customer">
				{{ selectedCustomer.name }}
				<button class="remove" href="" v-on:click.prevent="selectedCustomer = null">-</button>
			</div>
			<div v-if="selectedCustomer === null">
				<input type="text" name="customer" v-model="customerSearchTerm" placeholder="Sök efter kund" id="customer-search">
				<br>
				<a href="" v-on:click.prevent="showAddView = true" id="add-new-customer">Lägg till ny kund</a>

				<div class="select-list" v-if="matchingCustomers.length > 0">
					<ul id="customer-list">
						<li v-for="customer in matchingCustomers"><a href="" v-on:click.prevent="selectedCustomer = customer">{{ customer.name }}</a>
						</li>
					</ul>
				</div>

				<div style="margin-top:24px;" v-if="matchingCustomers.length == 0 && customerSearchTerm != '' && this.customers !== null && this.customers !== 'wait'">
					Hittar tyvärr ingen kund som matchar angiven sökfras.
				</div>
			</div>
		</div>

		<div class="sidebar-form" v-if="showAddView">
			<h3 class="title-s">Lägg till ny kund</h3>
			<ul v-if="validationErrors.length > 0">
				<li v-for="error in validationErrors">{{ error }}</li>
			</ul>

			<input v-model="newCustomer.name" id="add-customer-name" type="text" placeholder="Namn">
			<input v-model="newCustomer.organisationNumber" id="add-customer-organisationnumber" type="text" placeholder="Organisationsnummer">
			<input v-model="newCustomer.reference" id="add-customer-reference" type="text" placeholder="Referens">
			<textarea v-model="newCustomer.address" id="add-customer-address" cols="30" rows="10" placeholder="Address"></textarea>

			<button @click="saveCustomer()" :class="{'disabled': newCustomer.valid == false}" id="new-customer-save">Spara kund</button>

			<div>
				<a href="" v-on:click.prevent="resetAddCustomer()">Avbryt</a>
			</div>
		</div>
	</div>
</template>