<script>
	import Vue from 'vue';
	import ClickOutside from 'vue-click-outside';
	import _ from 'underscore';

	export default {
		props: [
			'name',
			'icon',
			'selectedid',
			'optionsjson',
		],

		data: function() {
			return {
				listVisible: false,
				selectedValueName: 'Välj...', // Add old value NAME here
				value: null, // Add old value ID here
				items: [],
				itemSearchTerm: ''
			}
		},

		created: function() {
			let _this = this;

			this.items = JSON.parse(this.optionsjson);

			if ( this.selectedid !== null )
			{
				let selectedItem = _.find(this.items, function(item) {
					return item.id == _this.selectedid;
				});

				if ( selectedItem )
				{
					this.setValue(selectedItem);
				}
			}
		},

		methods: {
			setValue(item)
			{
				this.value = item.id;
				this.selectedValueName = item.name;
				this.hideList();
			},
			hideList()
			{
				this.listVisible = false;
			}
		},

		computed: {
			filteredItems: function() {
				const self = this;
				return this.items.filter(function(item) {
					return item.name.toLowerCase().indexOf(self.itemSearchTerm.toLowerCase()) >= 0;
				});
			}
		},

		directives: {
			ClickOutside
		}
	}
</script>

<template>
	<div class="searchable-dropdown" v-click-outside="hideList">
		<div v-on:click="listVisible = !listVisible">
			<p>{{ selectedValueName }}</p>
			<img :src="icon"/>
			<input type="hidden" :name="name" v-model="value"/>
		</div>
		<div class="filter-and-list" v-if="listVisible">
			<div class="close-dropdown" v-on:click="listVisible = false">Stäng</div>
			<input placeholder="Sök i listan..." v-model="itemSearchTerm" class="dropdown-filter">
			<ul>
				<li v-for="item in filteredItems" v-on:click="setValue(item)">{{ item.name }}</li>
			</ul>
		</div>
	</div>
</template>