<script>
	import _ from 'underscore';
	import {EventBus} from '../../event-bus';

	export default {

		props: {
			header: {},
			json: {},
			deleteconfirmation: {
				default: 'Säker?',
			},
		},

		data: function() {
			return {
				attachments: [],
				attachmentFields: 0,
			}
		},

		created: function() {
			this.attachments = JSON.parse(this.json);
		},

		mounted: function() {
			EventBus.$on('invoice-edit-add-attachment', this.addAttachment);
		},

		methods: {
			formatAmount: function(amount) {
				return Currency.format(amount);
			},

			deleteAttachment: function(attachmentToRemove)
			{
				if ( confirm(this.deleteconfirmation) )
				{
					this.attachments = _.filter(this.attachments, function(attachment) {
						return attachmentToRemove !== attachment;
					});
				}
			},

			addAttachment: function()
			{
				this.attachmentFields++;
			}
		},
	}
</script>

<template>
	<div>
		<input type="hidden" name="attachments" :value="JSON.stringify(attachments)">

		<div class="project-data-row" v-if="attachments.length > 0 || attachmentFields > 0">
			<h3 class="title-xs">{{ header }}</h3>

			<div class="project-data-row">
				<div v-for="attachment in attachments" class="project-data-cell flex flex-center invoice-job invoice-cell">
					<a :href="attachment.download_url" target="_blank">{{ attachment.original_filename }}</a>
					<a href="" class="delete in-cell" v-on:click.prevent="deleteAttachment(attachment)"><img src="/img/delete.svg"></a>
				</div>

				<div v-for="attachmentField in attachmentFields">
					<input type="file" name="attachment_file[]">
				</div>

				<div v-if="attachments.length === 0 && attachmentFields === 0" class="project-data-cell flex flex-center invoice-job invoice-cell">Tomt!</div>
			</div>
		</div>
	</div>
</template>