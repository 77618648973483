<script>
	import Vue from 'vue';
	import VueResource from 'vue-resource';
	import _ from 'underscore';

	import Card from './card.vue';
	import Forecast from './forecast.vue';
	import SelectCustomer from './select-customer.vue';
	import SelectProjectType from './select-project-type.vue';
	import SelectProjectAndInvoiceManager from './select-project-and-invoice-manager.vue';
	import SelectTeam from './select-team.vue';
	import Datepicker from 'vuejs-datepicker';
	import moment from 'moment';

	Vue.use(VueResource);

	export default {
		components: {
			Card,
			Forecast,
			SelectCustomer,
			SelectProjectType,
			SelectProjectAndInvoiceManager,
			SelectTeam,
			Datepicker,
		},

		props: [
			'cancelUrl',
		],

		data: function() {
			return {
				submitting: false,
				steps: [
					{
						name: 'customer',
						condition: {}
					},
					{
						name: 'projectName',
						condition: {}
					},

					{
						name: 'projectType',
						condition: {}
					},
					{
						name: 'revenue',
						condition: {}
					},
					{
						name: 'hourlyPrice',
						condition: {
							projectType: 'hourly'
						}
					},
					{
						name: 'hourlyPrice',
						condition: {
							projectType: 'retainer'
						}
					},
					{
						name: 'hours',
						condition: {
							projectType: 'fixed_price'
						}
					},
					{
						name: 'projectDates',
						condition: {}
					},
					{
						name: 'team',
						condition: {}
					},
					{
						name: 'projectAndInvoiceManager',
						condition: {}
					},
					{
						name: 'confirm',
						condition: {}
					}
				],

				currentStep: 'customer',
				settings: {},

				startDate: null,
				endDate: null,

				form: {
					projectName: '',
					description: '',
					customer: null,
					customerName: '',
					customerReference: null,
					projectType: null,
					startDate: '',
					endDate: '',
					revenue: null,
					hourlyPrice: null,
					hours: null,
					team: null,
					projectManager: null,
					invoiceManager: null,
				},

				visual: {
					animate: false,
					background: [
						'bg-trickle-blue',
						'bg-trickle-pink',
						'bg-trickle-orange',
						'bg-trickle-blue',
						'bg-trickle-pink',
						'bg-trickle-orange',
						'bg-trickle-blue',
						'bg-trickle-pink',
						'bg-trickle-orange',
						'bg-trickle-blue',
					]
				}
			};
		},

		watch: {
			startDate: function(startDate) {
				this.form.startDate = startDate ? moment(startDate).format('Y-MM-DD') : '';
			},

			endDate: function(endDate) {
				this.form.endDate = endDate ? moment(endDate).format('Y-MM-DD') : '';
			},
		},

		methods: {
			isStepValid()
			{
				switch (this.currentStep)
				{
					case 'projectName':
						return this.form.projectName.length > 0;
						break;
					case 'customer':
						return this.form.customer !== null;
						break;
					case 'projectType':
						return ['fixed_price', 'hourly', 'retainer'].includes(this.form.projectType);
						break;
					case 'projectDates':
						if ( this.form.projectType === 'fixed_price' )
						{
							return this.form.startDate.length > 0 && this.form.endDate.length > 0;
						}
						else
						{
							return this.form.startDate.length > 0;
						}
						break;
					case 'revenue':
						return !isNaN(this.form.revenue) && this.form.revenue > 0;
						break;
					case 'hourlyPrice':
						return !isNaN(this.form.hourlyPrice) && this.form.hourlyPrice > 0;
						break;
					case 'hours':
						return !isNaN(this.form.hours) && this.form.hours > 0;
						break;
					case 'team':
						return this.form.team !== null;
						break;
					case 'projectAndInvoiceManager':
						return this.form.projectManager !== null && this.form.invoiceManager !== null;
						break;
				}

				return false;
			},

			setCustomer(customer)
			{
				this.form.customer = customer;
				this.form.customerName = customer === null ? '' : customer.name;
				console.debug('Customer set to', customer);
			},

			setProjectType(type)
			{
				if ( this.form.projectType !== null && this.form.projectType !== type )
				{
					// If we change project type
					this.form.revenue = null;
					this.form.hourlyPrice = null;
					this.form.hours = null;
				}

				this.form.projectType = type;
				console.debug('Project type set to', type);

				if ( this.isStepValid() )
				{
					this.next();

				}
			},

			setTeam(team)
			{
				console.debug('Team set to', team);
				this.form.team = team;
			},
			setProjectManager(user)
			{
				console.debug('Project manager set to', user);
				this.form.projectManager = user;
			},
			setInvoiceManager(user)
			{
				console.debug('Account manager set to', user);
				this.form.invoiceManager = user;
			},

			isViewActive(step, projectType = null)
			{
				if ( step === this.currentStep && (projectType === null || projectType === this.form.projectType) )
				{
					return true;
				}

				return false;
			},

			getCurrentStepIndex()
			{
				let currentStepIndex = null;
				let _this = this;

				_.each(this.steps, function(step, i) {
					if ( step.name === _this.currentStep )
					{
						currentStepIndex = i;
					}
				});

				return currentStepIndex;
			},

			getAdjacentStep(prev = false)
			{
				let selectNext = false;
				let _this = this;
				let adjacentStep = null;

				let steps = this.steps;

				if ( prev )
				{
					steps = _.clone(this.steps).reverse();
				}

				_.each(steps, function(step) {
					if ( step.name === _this.currentStep )
					{
						selectNext = true;
						return;
					}

					if ( selectNext && adjacentStep === null )
					{
						let conditionFullfilled = true;
						_.each(step.condition, function(value, key) {
							if ( _this.form[key] != value )
							{
								conditionFullfilled = false;
							}
						});

						if ( conditionFullfilled )
						{
							adjacentStep = step.name;
						}
					}
				});

				return adjacentStep;
			},

			next()
			{
				if ( !this.isStepValid() )
				{
					return;
				}

				this.currentStep = this.getAdjacentStep();
				console.debug('Changing to step', this.currentStep);
				this.changeView();
			},

			prev()
			{
				this.currentStep = this.getAdjacentStep(true);
				console.debug('Changing to step', this.currentStep);
			},

			changeView()
			{
				this.visual.animate = true;
				setTimeout(() => this.visual.animate = false, 120);
			},

			getFixedPriceHourlyPrice()
			{
				if ( this.form.projectType !== 'fixed_price' || isNaN(this.form.hours) || !(this.form.hours > 0) || isNaN(this.form.revenue) )
				{
					return '-';
				}

				return Math.round(this.form.revenue / this.form.hours);
			},

			submit()
			{
				if ( this.submitting )
				{
					console.debug('Already trying to save project. Ignoring.');
					return;
				}

				console.debug('Saving project...');

				let data = {
					_token: this.settings.csrfToken,
					customer: this.form.customer.id,
					customer_reference: this.form.customer_reference,
					project_manager: this.form.projectManager.id,
					invoice_manager: this.form.invoiceManager.id,
					name: this.form.projectName,
					description: this.form.description,
					start_date: this.form.startDate,
					end_date: this.form.endDate,
					project_type: this.form.projectType,
					team: this.form.team.id
				};

				if ( this.form.projectType === 'fixed_price' )
				{
					data.total_hours = this.form.hours;
					data.total_revenue = this.form.revenue;
				}
				else if ( this.form.projectType === 'hourly' )
				{
					data.hourly_rate = this.form.hourlyPrice;
					data.estimated_monthly_revenue = this.form.revenue;
				}
				else if ( this.form.projectType === 'retainer' )
				{
					data.hourly_rate = this.form.hourlyPrice;
					data.monthly_revenue = this.form.revenue;
				}
				else
				{
					console.error('Invalid project type.');
					return;
				}

				this.submitting = true;

				this.$http.post(this.settings.projectSaveUrl, data).then(response => {
					console.debug('Redirecting...');
					if ( response.body.success )
					{
						this.currentStep = 'completed';

						if ( response.body.project_url )
						{
							setTimeout(function() {
								window.location = response.body.project_url;
							}, 2000)
						}
					}
				}, response => {
					console.error('Error when saving project.');
				});
			}
		},

		created: function() {
			this.settings = JSON.parse(document.getElementById('create-project-settings').innerHTML);
		},
	}
</script>
<template>
	<div class="flex clearfix create-project">
		<aside class="col-4 sidebar create-project-sidebar no-gutter">
			<nav class="sidebar-navigation">
				<a :href="cancelUrl" class="float-right color-red">Avbryt</a>

				<a v-if="getCurrentStepIndex() != 0" href="javascript:;" @click="prev">Gå tillbaka</a>
			</nav>

			<section class="sidebar-content">

				<article class="step" v-if="isViewActive('projectName')">
					<h3 class="title-s">Ge ditt projekt<br> ett namn</h3>
					<p>Tips! Passa på att skapa en mapp på drive när du ändå är igång!</p>
					<div class="sidebar-form">
						<input type="text" v-model="form.projectName" placeholder="Skriv projektnamn här" id="project-name">
						<input type="text" placeholder="Beskrivning" v-model="form.description" id="project-description">
					</div>
				</article>

				<article class="step" v-if="isViewActive('customer')">
					<select-customer v-on:customer-selected="setCustomer"
									 :csrftoken="settings.csrfToken"
									 :jsonurl="settings.customerJsonUrl"
									 :createurl="settings.customerCreateUrl"
									 :customer="form.customer"></select-customer>

					<h3 class="title-s" style="margin-top: 70px;">Kundens referens</h3>
					<input type="text" v-model="form.customer_reference" :placeholder="form.customer ? form.customer.reference : 'Kundens referens'">
				</article>

				<article class="step" v-if="isViewActive('projectType')">
					<h3 class="title-s">Välj faktureringstyp</h3>
					<select-project-type v-on:project-type-selected="setProjectType"></select-project-type>
				</article>

				<article class="step" v-if="isViewActive('projectDates')">
					<h3 class="title-s">Projektstart</h3>
					<div class="date-holder">
						<label for="">Startdatum</label>
						<datepicker
							placeholder="Välj datum"
							name="startdate"
							id="project-start-date"
							v-model="startDate"
							value="form.startDate"
							format="yyyy-MM-dd"
						></datepicker>
					</div>
					<div class="date-holder">
						<label for="" v-if="form.projectType == 'fixed_price'">Slutdatum</label>
						<label for="" v-if="form.projectType != 'fixed_price'">Slutdatum (frivilligt)</label>
						<datepicker
							placeholder="Välj datum"
							name="enddate"
							id="project-end-date"
							v-model="endDate"
							format="yyyy-MM-dd"
							value="form.endDate"
						></datepicker>
					</div>
				</article>

				<!-- RETAINER -->
				<article class="step sub" v-if="isViewActive('revenue', 'retainer') ">
					<span class="type">Retainer</span>
					<input type="text" v-model="form.revenue" placeholder="Ange intäkt per månad">
				</article>

				<article class="step sub" v-if="isViewActive('hourlyPrice', 'retainer') ">
					<span class="type">Timpris</span>
					<input type="text" v-model="form.hourlyPrice" placeholder="Timpris">
				</article>
				<!-- /RETAINER -->

				<!-- HOURLY -->
				<article class="step sub" v-if="isViewActive('revenue', 'hourly') ">
					<span class="type">Löpande</span>
					<input type="text" v-model="form.revenue" placeholder="Uppskattad intäkt/månad">
				</article>

				<article class="step sub" v-if="isViewActive('hourlyPrice', 'hourly') ">
					<span class="type">Timpris</span>
					<input type="text" v-model="form.hourlyPrice" placeholder="Timpris">
				</article>
				<!-- /HOURLY -->

				<!-- FIXED -->
				<article class="step sub" v-if="isViewActive('revenue', 'fixed_price')  ">
					<span class="type">Fast pris</span>
					<input type="text" v-model="form.revenue" placeholder="Total budget" id="project-fixed-revenue">
				</article>

				<div v-if="isViewActive('hours', 'fixed_price')">
					<article class="step sub">
						<span class="type">Tidsuppskattning</span>
						<input type="text" v-model="form.hours" placeholder="Antal timmar" id="project-fixed-hours">
					</article>
				</div>
				<!-- /FIXED -->

				<article class="step" v-if="isViewActive('team')">
					<select-team v-on:team-updated="setTeam" :jsonurl="settings.teamsJsonUrl" :form="form"></select-team>
				</article>

				<article class="step" v-if="isViewActive('projectAndInvoiceManager')">
					<select-project-and-invoice-manager v-on:project-manager-updated="setProjectManager"
								  v-on:invoice-manager-updated="setInvoiceManager" :jsonurl="settings.usersJsonUrl" :form="form"></select-project-and-invoice-manager>
				</article>

				<article class="step" v-if="isViewActive('confirm')">
					<h3 class="title-s">Bra!</h3>
					<p>Kika igenom ditt projektkort till höger och se så allt ser rätt ut!</p>
				</article>

				<article class="step" v-if="isViewActive('completed')">
					<h3 class="title-s">Tack!</h3>
					<p>Ditt projekt är nu skapat och redo att börja faktureras.</p>
				</article>

				<div v-if="! isViewActive('completed')">
					<button @click="next" :class="{'disabled': !isStepValid()}" v-if="! isViewActive('projectType') && ! isViewActive('confirm')"
							id="next-step">Gå vidare
					</button>
					<button @click="submit" v-if="isViewActive('confirm')" :class="{'disabled': submitting}" id="save-project">Spara projekt</button>
				</div>
			</section>
		</aside>

		<main id="main-view" class="col-8  no-gutter create-project-content"
			  :class="[{'animate' : visual.animate }, visual.background[getCurrentStepIndex()]]">

			<card :form="form"></card>

		</main>
	</div>
</template>