<script>
	export default {
		methods: {
			update()
			{
				let field = event.target.name;
				let value = event.target.value;

				this.$emit('update', {
					'field': field,
					'value': value
				});
			}
		},
	}
</script>

<template>
	<div class="sidebar-form">
		<input type="text" @input="update" name="monthlyBudget" placeholder="Ange budget per månad">
		<input type="text" @input="update" name="estimatedMonthlyHours" placeholder="Ange tidsprognos">
	</div>
</template>