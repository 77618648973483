<script>
	import _ from 'underscore';

	export default {

		data: function() {
			return {
				searchterm: '',
				editUser: null,
				users: [],
				timer: null,
				loadingUsers: false
			}
		},

		methods: {
			enableEdit(user)
			{
				this.editUser = user.id;
				user.oldData = _.clone(user);
			},

			cancelEdit(user)
			{
				user = _.extend(user, user.oldData);
				this.editUser = null;
				user.oldData = null;
			},

			saveEdit(user)
			{
				let data = {
					_method: 'PUT',
					_token: this.settings.csrfToken,
					manager_id: user.manager_id,
					role_ids: user.role_ids,
					employment_level: user.employment_level,
					employment_label: user.employment_label
				};

				this.$http.post(user.update_url, data).then(response => {
					user = _.extend(user, response.body);
					this.editUser = null;
					user.oldData = null;

					// Reload user date in case manager role was added/removed
					this.loadUsers();
				}, response => {
					if ( response.status === 422 )
					{
						let messages = [];

						_.each(response.body, function(item) {
							messages.push(item[0].charAt(0).toUpperCase() + item[0].slice(1));
						});

						user.validation_error = messages.join(' ');

						this.timer = setTimeout(function() {
							user.validation_error = null;
						}, 5000);
					}
					else
					{
						user.validation_error = 'Något gick fel. Kunde inte spara dina ändringar.';

						this.timer = setTimeout(function() {
							user.validation_error = null;
						}, 5000);

						console.error('Error when updating user.');
					}
				});
			},

			search()
			{
				this.users = [];
				this.loadUsers();
			},

			loadUsers()
			{
				this.loadingUsers = true;

				this.$http.get(this.settings.usersJsonUrl, {
					params: {
						s: this.searchterm
					}
				}).then(response => {
					let users = response.body.users;

					_.each(users, function(user) {
						user.validation_error = null;
					});

					this.users = users;
					this.managers = response.body.managers;

					this.loadingUsers = false;
				}, response => {
					console.error('Error fetching users.');
					this.loadingUsers = false;
				});
			}
		},

		created: function() {
			this.settings = JSON.parse(document.getElementById('vue-settings').innerHTML);

			this.loadUsers();
		},

		watch: {}
	}
</script>

<template>
	<div class="invoice-overview">

		<header class="main-header bg-trickle-blue">
			<section class="main-header-navigation">
				<div class="holder clearfix">
					<nav class="main-navigation">
						<a href="/dashboard">Dashboard</a>
					</nav>
					<div class="align-center">
						<h1 class="title-m">Användare</h1>
					</div>
					<nav class="sub-navigation">
						<a href=""><img class="icon" src="/img/icon-timer-white.svg" alt="">Logga tid</a>
					</nav>
				</div>
			</section>
			<section class="main-header-filter">
				<div class="holder">
					<fieldset>
						<label for="searchterm">Sök</label>
						<input onclick="this.select();" type="text" name="s" id="searchterm" v-model="searchterm" v-on:keyup.enter="search()">
					</fieldset>
				</div>
			</section>
		</header>

		<main>
			<div class="holder">
				<div v-if="users.length == 0 && loadingUsers" class="user-list" style="text-align: center">
					<img src="/img/loader1.svg" alt="" width="50px" height="50px">
				</div>

				<table class="user-list" v-if="users.length > 0 || ! loadingUsers">
					<tr>
						<th></th>
						<th class="nowrap">Namn / e-post</th>
						<th class="nowrap">Närmsta chef</th>
						<th>Roll</th>
						<th class="nowrap">Anställningsgrad</th>
						<th>Kommentar</th>
						<th></th>
					</tr>
					<tr v-for="user in users">
						<td>
							<img :src="user.avatar" alt="" class="avatar" style="max-width: 50px;">
						</td>
						<td class="nowrap">
							{{ user.name }}<br>{{ user.email }}
						</td>
						<td class="nowrap">
							<span v-if="editUser != user.id">
								{{ user.manager_name }}
							</span>
							<span v-if="editUser == user.id">
								<select v-model="user.manager_id">
									<option v-for="manager in managers" v-bind:value="manager.id">{{ manager.name }}</option>
								</select>
							</span>
						</td>
						<td>
							<span v-if="editUser != user.id">
								{{ user.roles_string }}
							</span>
							<span v-if="editUser == user.id">
								<select v-model="user.role_ids" multiple>
									<option v-for="role in settings.roles" v-bind:value="role.id">{{ role.name }}</option>
								</select>
							</span>
						</td>
						<td class="nowrap">
							<span v-if="editUser != user.id">
								{{ user.employment_level }} %
							</span>
							<span v-if="editUser == user.id">
								<input type="text" v-model="user.employment_level">
							</span>
						</td>
						<td>
							<span v-if="editUser != user.id">
								{{ user.employment_label }}
							</span>
							<span v-if="editUser == user.id">
								<input type="text" v-model="user.employment_label">
							</span>
						</td>
						<td>
							<a href="" v-if="editUser != user.id" v-on:click.prevent="enableEdit(user)">Redigera</a>
							<span v-if="editUser == user.id && ! user.validation_error">
								<a href="" v-on:click.prevent="saveEdit(user)">Spara</a> <a href="" v-on:click.prevent="cancelEdit(user)">Avbryt</a>
							</span>
							<div v-if="editUser == user.id && user.validation_error" class="validation-errors-overlay">
								{{ user.validation_error }}
							</div>
						</td>
					</tr>

				</table>
			</div>
		</main>

	</div>
</template>