import Vue from 'vue';

import CreateProject from './components/create-project/create-project.vue'
import BreakCheckin from './components/break-checkin.vue';
import UserAdmin from './components/user-admin.vue';
import Bot from './components/bot.vue';
import BotButton from './components/bot-button.vue';
import ContextMenu from './components/context-menu.vue';
import EditTeamMembers from './components/project/edit-team-members.vue';
import EditProjectExpenses from './components/project/edit-project-expenses.vue';
import ProjectForecast from './components/project/project-forecast.vue';
import SearchableDropdown from './components/searchable-dropdown.vue';
import AjaxButton from './components/ajax-button.vue';
import EventButton from './components/event-button.vue';
import InvoiceRows from './components/invoice/invoice-rows.vue';
import InvoiceSum from './components/invoice/invoice-sum.vue';
import InvoiceAttachments from './components/invoice/invoice-attachments.vue';
import Datepicker from 'vuejs-datepicker';

module.exports = function() {
	Vue.directive('focus', {
		inserted: function(el) {
			el.focus();
		}
	});

	let app = new Vue({
		el: '#vue-app',
		components: {
			CreateProject,
			BreakCheckin,
			UserAdmin,
			Bot,
			BotButton,
			ContextMenu,
			EditTeamMembers,
			SearchableDropdown,
			AjaxButton,
			EventButton,
			EditProjectExpenses,
			ProjectForecast,
			InvoiceRows,
			InvoiceSum,
			InvoiceAttachments,
			Datepicker,
		}
	});
};