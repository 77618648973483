<script>
	import Fuse from 'fuse.js';
	import _ from 'underscore';
	import ClickOutside from 'vue-click-outside';

	export default {

		props: [
			'jsonurl',
			'form'
		],

		data: function() {
			return {
				team: null,
				searchTerm: '',
				teams: null,
				matchingTeams: []
			}
		},

		created: function() {
			this.projectManager = this.form.projectManager;
			this.invoiceManager = this.form.invoiceManager;
		},

		methods: {
			updateMatching()
			{
				if ( !(this.teams instanceof Fuse) )
				{
					return;
				}

				let matchingTeams = this.teams.search(this.searchTerm);
				this.matchingTeams = matchingTeams.slice(0, 10);
			},

			hideTeamlist()
			{
				this.searchTerm = '';
			},

			setTeam(team)
			{
				this.team = team;
				this.searchTerm = '';
				this.$refs.searchterm.focus();
			},
		},

		watch: {
			team: function() {
				this.$emit('team-updated', this.team);
			},
			searchTerm: function() {
				if ( this.teams === null )
				{
					this.teams = 'wait';

					this.$http.get(this.jsonurl).then(response => {
						console.debug('Teams loaded.');

						this.teams = new Fuse(response.body, {
							threshold: 0.1,
							matchAllTokens: true,
							tokenize: true,
							keys: ['title']
						});

						this.updateMatching();
					}, response => {
						console.error('Error: Could not load teams.');
						this.teams = null;
					});
				}

				this.updateMatching();
			}
		},

		directives: {
			ClickOutside,
		}
	}
</script>

<template>
	<div class="team">
		<span class="type">Välj team</span>
		<div class="select-team">
			<input ref="searchterm" type="text" v-model="searchTerm" placeholder="Sök team" id="team-search">

			<div class="select-team-list align-left" v-if="matchingTeams.length > 0" v-click-outside="hideTeamlist">
				<ul>
					<li class="" v-for="matchingTeam in matchingTeams">
						<a href="" v-on:click.prevent="setTeam(matchingTeam)" id="add-team">{{ matchingTeam.title }}</a>
					</li>
				</ul>
			</div>

			<div v-if="matchingTeams.length == 0 && searchTerm != '' && this.teams !== null && this.teams !== 'wait'">
				Hittar tyvärr inget team som matchar angiven sökfras.
			</div>
		</div>


		<h3 class="title-s">Valt</h3>
		<div class="selected-team" v-if="team != null">{{ team.title }}
			<button class="member-remove" v-on:click.prevent="team = null">-</button>
		</div>
	</div>
</template>