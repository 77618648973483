<script>
	import Fuse from 'fuse.js';
	import _ from 'underscore';
	import ClickOutside from 'vue-click-outside';

	export default {

		props: [
			'jsonurl',
			'form'
		],

		data: function() {
			return {
				projectManager: null,
				invoiceManager: null,
				searchTerm: '',
				users: null,
				matchingUsers: []
			}
		},

		created: function() {
			this.projectManager = this.form.projectManager;
			this.invoiceManager = this.form.invoiceManager;
		},

		methods: {
			updateMatching()
			{
				if ( !(this.users instanceof Fuse) )
				{
					return;
				}

				let matchingUsers = this.users.search(this.searchTerm);
				this.matchingUsers = matchingUsers.slice(0, 10);
			},

			hideUserList()
			{
				this.searchTerm = '';
			},

			setProjectManager(user)
			{
				this.projectManager = user;
				this.searchTerm = '';
				this.$refs.searchterm.focus();
			},

			setInvoiceManager(user)
			{
				this.invoiceManager = user;
				this.searchTerm = '';
				this.$refs.searchterm.focus();
			},
		},

		watch: {
			projectManager: function() {
				this.$emit('project-manager-updated', this.projectManager);
			},
			invoiceManager: function() {
				this.$emit('invoice-manager-updated', this.invoiceManager);
			},
			searchTerm: function() {
				if ( this.users === null )
				{
					this.users = 'wait';

					this.$http.get(this.jsonurl).then(response => {
						console.debug('Users loaded.');

						this.users = new Fuse(response.body, {
							threshold: 0.1,
							matchAllTokens: true,
							tokenize: true,
							keys: ['name']
						});

						this.updateMatching();
					}, response => {
						console.error('Error: Could not load users.');
						this.users = null;
					});
				}

				this.updateMatching();
			}
		},

		directives: {
			ClickOutside,
		}
	}
</script>

<template>
	<div class="team">
		<span class="type">Välj projektledare och fakturaansvarig</span>
		<div class="select-team">
			<input ref="searchterm" type="text" v-model="searchTerm" placeholder="Sök person" id="project-invoice-manager-search">

			<div class="select-team-list align-left" v-if="matchingUsers.length > 0" v-click-outside="hideUserList">
				<ul>
					<li class="" v-for="user in matchingUsers">
						<span>{{ user.name }}</span>
						<div class="list-buttons">
							<button class="member-add" v-if="projectManager == null" v-on:click.prevent="setProjectManager(user)"
									id="add-pl">PL
							</button>
							<button class="member-add" v-if="invoiceManager == null" v-on:click.prevent="setInvoiceManager(user)"
									id="add-ka">FA
							</button>
						</div>
					</li>
				</ul>
			</div>

			<div v-if="matchingUsers.length == 0 && searchTerm != '' && this.users !== null && this.users !== 'wait'">
				Hittar tyvärr ingen användare som matchar angiven sökfras.
			</div>
		</div>


		<h3 class="title-s">Valda</h3>
		<div class="selected-team" v-if="projectManager != null">{{ projectManager.name }} (PL)
			<button class="member-remove" v-on:click.prevent="projectManager = null">-</button>
		</div>
		<div class="selected-team" v-if="invoiceManager != null">{{ invoiceManager.name }} (FA)
			<button class="member-remove" v-on:click.prevent="invoiceManager = null">-</button>
		</div>
	</div>
</template>