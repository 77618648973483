<script>
	import ClickOutside from 'vue-click-outside';

	export default {
		props: {
			icon: String,
			links: {
				type: Array,
				default: []
			}
		},
		data: function() {
			return {
				menuVisible: false,
			}
		},
		methods: {
			hideMenu()
			{
				this.menuVisible = false;
			}
		},
		directives: {
			ClickOutside
		}
	}
</script>

<template>
	<div class="project-list-item-button context-menu-daddy" v-click-outside="hideMenu" v-on:click="menuVisible = ! menuVisible">
		<img :src="icon"/>
		<nav class="context-menu" v-if="menuVisible">
			<a v-for="link in links" :href="link.url">{{ link.name }}</a>
		</nav>
	</div>
</template>