import Accounting from 'accounting-js';

export default {
	format: function(amount)
	{
		let precision = 2;

		if ( amount === Math.round(amount) )
		{
			precision = 0;
		}

		return Accounting.formatMoney(amount, {symbol: 'kr', format: '%v %s', thousand: ' ', decimal: ',', precision: precision});
	}
}