<!--suppress CommaExpressionJS -->
<script>
	import _ from 'underscore';
	import Currency from '../helpers/currency';
	import ClickOutside from 'vue-click-outside';

	export default {
		name: 'project-forecast',

		props: [
			'currentforecast',
			'revenue',
			'netrevenue',
			'invoicedtotal',
			'invoicednet',
			'projecttype',
			'saveurl',
			'reseturl',
			'csrftoken',
			'readonly',
		],

		data: function() {
			return {
				forecast: [],
				forecastSum: 0,
				showSaveConfirmation: false,
				budgetRemainder: 0,
			}
		},

		created: function() {
			let forecast = JSON.parse(this.currentforecast);

			// Add some new properties that we'll use to keep track of states
			this.setForecast(forecast);
			this.updateBudgetRemainder();
		},

		methods: {
			setForecast: function(forecast) {
				forecast.map(function(month) {
					month._edit = false;
				});

				this.forecast = forecast;
			},

			formatAmount: function(amount) {
				return Currency.format(amount);
			},

			toggleEdit: function(month) {
				month._edit = !month._edit;
			},

			disableEdit: function() {
				this.forecast.map(function(month) {
					month._edit = false;
				});
			},

			updateBudgetRemainder: function() {
				let total = 0;

				this.forecast.map(function(month) {
					if (month.revenue !== undefined && month.revenue !== '')
					{
						total += parseFloat(month.revenue);
					}
				});

				this.forecastSum = total;
				this.budgetRemainder = this.netrevenue - total;
			},

			setChanged: function(month)
			{
				console.debug('Forecast revenue updated.');
				console.log(month);

				if (month.type === 'auto')
				{
					month.type = 'manual_estimate';
				}

				this.recalculateForecast();
				this.updateBudgetRemainder();
			},

			recalculateForecast: function()
			{
				if ( this.projecttype !== 'fixed_price' )
				{
					// Do nothing for non-fixed price projects
					return;
				}

				let totalRevenue = parseFloat(this.netrevenue);

				let numItemsToAdjust = 0;
				let sums = {
					invoiced: 0,
					manual_estimate: 0,
					auto: 0,
				};

				_.each(this.forecast, function(forecastItem) {
					if ( forecastItem.type === 'auto' )
					{
						numItemsToAdjust++;
					}

					if (forecastItem.revenue > 0)
					{
						sums[forecastItem.type] += parseFloat(forecastItem.revenue);
					}
				});

				let leftToAssign = totalRevenue - sums.invoiced - sums.manual_estimate;

				let n = 0;
				let sumAssigned = 0;

				this.forecast.map(function(forecastItem) {
					if ( forecastItem.type === 'auto' )
					{
						n++;
						let sum = Math.max(Math.ceil(leftToAssign / numItemsToAdjust), 0);

						if ( n < numItemsToAdjust )
						{
							forecastItem.revenue = sum;
							sumAssigned += sum;
						}
						else
						{
							// Make sure we match the total project revenue exactly by
							// adjusting the last automatic monthly estimate
							forecastItem.revenue = Math.max(leftToAssign - sumAssigned, 0);
							sumAssigned += Math.max(leftToAssign - sumAssigned, 0);
						}
					}
				});
			},

			resetManualEstimates: function() {
				if (confirm('Säker? Alla manuella justeringar i prognosen som inte är låsta kommer att tas bort.'))
				{
					this.$http.post(this.saveurl, {forecast: this.forecast, _token: this.csrftoken}).then(response => {
						this.setForecast(response.body.forecastJson);

						this.$http.post(this.reseturl, {_token: this.csrftoken}).then(response => {
							this.setForecast(response.body.forecastJson);
							this.updateBudgetRemainder();
						}, response => {
							// error callback
						});
					}, response => {
						// error callback
					});
				}
			},

			save: function() {
				this.$http.post(this.saveurl, {forecast: this.forecast, _token: this.csrftoken}).then(response => {
					this.saveConfirmation();
					this.setForecast(response.body.forecastJson);
				}, response => {
					// error callback
				});
			},

			saveConfirmation: function()
			{
				this.showSaveConfirmation = true;

				let _this = this;

				setTimeout(function() {
					_this.showSaveConfirmation = false;
				}, 3000);
			}
		},

		directives: {
			ClickOutside
		}
	}
</script>

<template>
	<div class="project-data-row">

		<h3 class="title-s">Månadsprognos</h3>

		<div v-for="month, index in forecast" class="project-data-cell flex flex-center invoice-job invoice-cell">

			{{ month.label + (month.type == 'invoiced' ? ' (fakturerad)' : '') }}

			<div class="flex-right">
				<div style="line-height: 24px; margin: 8px 0;">
					<span v-if="! month._edit">
						{{ formatAmount(month.revenue) }}

						<i v-if="month.locked" class="mdi mdi-lock"></i>
						<i v-else class="mdi mdi-lock-open" style="color: gray"></i>

						<a v-if="month.type != 'invoiced' && readonly == 0" v-on:click.prevent="toggleEdit(month)" href="" class="delete in-cell"><img
							src="/img/icon-gear.svg"></a>
					</span>

					<div v-if="month._edit && month._edit == true" v-click-outside="disableEdit" style="text-align: right">
						<input type="number" style="display: inline-block; width: 50%; text-align: right; padding-right: 0;" v-model="month.revenue" @change="setChanged(month)" @keyup="setChanged(month)" v-focus v-on:keyup.enter="toggleEdit(month)"><br>
						<input type="checkbox" v-model="month.locked" @change="setChanged(month)" :id="'month-locked-' + index">
						<label style="display: inline;" :for="'month-locked-' + index">Lås till angivet värde</label>
					</div>
				</div>
			</div>
		</div>

		<div v-if="projecttype == 'fixed_price' && budgetRemainder !== 0" class="project-data-cell flex flex-center invoice-job invoice-cell">
			<div class="flex-right">
				<div>
					<span v-if="budgetRemainder < 0">
						Prognossumman är <strong>{{ formatAmount(Math.abs(budgetRemainder)) }}</strong> högre än budgeterad byråintäkt!
					</span>
					<span v-if="budgetRemainder > 0">
						Prognossumman är <strong>{{ formatAmount(Math.abs(budgetRemainder)) }}</strong> lägre än budgeterad byråintäkt!
					</span>
					<br>
					Du kanske vill <a href="" v-on:click.prevent="resetManualEstimates()">omfördela estimaten automatiskt</a>?
				</div>
			</div>
		</div>

		<div class="flex flex-right">
			<div class="col-8"></div>
			<div class="col-4 align-right">
				<i class="mdi mdi-lock"></i> = uppdateras inte vid automatisk omfördelning av prognosen
			</div>
		</div>

		<div v-if="projecttype == 'fixed_price'">
			<div class="flex flex-center">
				<div class="col-3">
					<div>
						<h3 class="title-xs">Total byråintäkt</h3>
						{{ formatAmount(netrevenue) }}

						<h3 class="title-xs">Fakturerad byråintäkt</h3>
						{{ formatAmount(invoicednet) }}

						<h3 class="title-xs">Kvarvarande byråintäkt</h3>
						{{ formatAmount(netrevenue - invoicednet) }}
					</div>
				</div>
				<div class="col-3">
					<div>
						<h3 class="title-xs">Total budget</h3>
						{{ formatAmount(revenue) }}

						<h3 class="title-xs">Fakturerat totalt</h3>
						{{ formatAmount(invoicedtotal) }}

						<h3 class="title-xs">Kvar att fakturera</h3>
						{{ formatAmount(revenue - invoicedtotal) }}
					</div>
				</div>
			</div>
		</div>

		<div v-if="projecttype == 'hourly'">
			<p>Den uppskattade månatliga intäkten är satt till {{ formatAmount(revenue) }}. Detta kan justeras ovan för specifika månader.</p>
			<br/>
		</div>
		<div v-if="projecttype == 'retainer'">
			<p>Den månatliga intäkten för detta projekt är satt till {{ formatAmount(revenue) }}. Detta kan justeras ovan för specifika månader.</p>
			<br/>
		</div>


		<div v-if="readonly == 0" class="sticky-bottom-nav">
			<a class="button" href="" v-on:click.prevent="save()">Spara</a>

			<a class="button red" href="" v-on:click.prevent="resetManualEstimates()">Omfördela automatiskt</a>

			<span v-if="showSaveConfirmation">Sparad!</span>
		</div>
	</div>
</template>