<script>
	import _ from 'underscore';
	import ClickOutside from 'vue-click-outside';
	import {EventBus} from '../event-bus';

	export default {

		props: [
			'todaysdate'
		],

		data: function() {
			return {
				showChat: false,
				messages: [],
				message: ''
			}
		},

		created: function() {
			let _this = this;

			this.addBotMessage('Hej! Vad kan jag hjälpa till med?');
		},

		mounted: function() {
			let _this = this;

			EventBus.$on('bot-button-clicked', function() {
				_this.showChat = ! _this.showChat;
			});
		},

		methods: {
			submitMessage()
			{
				if ( this.message === '' )
				{
					return;
				}

				this.addMessage(this.message, false);

				this.$http.post('/botman', {
					driver: 'ohmy-web',
					message: this.message
				}).then(response => {
					let messages = response.data.messages || [];
					messages.forEach(msg => {
						this.addBotMessage(msg.text);
					});
				}, response => {

				});

				this.message = '';
			},

			addMessage(text, reply = false)
			{
				let _this = this;

				let message = {
					text: text,
					isReply: reply,
					isVisible: false,
				};

				this.messages.push(message);

				setTimeout(function() {
					message.isVisible = true;
				}, 10);

				setTimeout(function() {
					_this.$refs.messages.scrollTop = _this.$refs.messages.scrollHeight;
				}, 0);
			},

			addBotMessage(text)
			{
				this.addMessage(text, true);
			},
			hideChat()
			{
				this.showChat = false;
			}
		},
		directives: {
			ClickOutside
		},
		watch: {
			showChat: function(value, oldValue) {
				if ( value === true )
				{
					this.$refs.botinput.focus();
				}
			}
		}
	}
</script>

<template>
	<aside id="bot" class="bot" :class="{'is-active': showChat}" v-click-outside="hideChat">
		<header>
			<h3 class="title-s"><img src="/img/icon-bot.svg">BotBrasse</h3>
		</header>
		<section class="messages" ref="messages">
			<span class="date">{{ todaysdate }}</span>

			<div v-for="message in messages" class="message" :class="{'reply': message.isReply, 'is-visible': message.isVisible}">
				<span>{{ message.text }}</span>
			</div>
		</section>
		<footer>
			<input ref="botinput" v-model="message" v-on:keyup.enter="submitMessage" type="text" placeholder="Svara...">
		</footer>
	</aside>
</template>