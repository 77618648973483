<script>
	import Currency from '../helpers/currency';
	import {EventBus} from '../../event-bus';

	export default {

		props: {
			nameworkrows: '',
			nameexpenserows: '',
		},

		data: function() {
			return {
				sumWork: 0,
				sumExpenses: 0,
			}
		},

		created: function() {
			EventBus.$on(this.nameworkrows + '-sum-updated', this.updateWorkSum);
			EventBus.$on(this.nameexpenserows + '-sum-updated', this.updateExpensesSum);
		},

		methods: {
			formatAmount: function(amount) {
				return Currency.format(amount);
			},

			updateWorkSum: function(sum)
			{
				this.sumWork = sum;
			},

			updateExpensesSum: function(sum)
			{
				this.sumExpenses = sum;
			},
		},
	}
</script>

<template>
	<div>
		<p class="title-xs">Att betala</p>
		<p class="amount color-green">{{ formatAmount(sumWork + sumExpenses) }}</p>
	</div>
</template>