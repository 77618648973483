<script>
	import Currency from '../helpers/currency';
	import _ from 'underscore';
	import {EventBus} from '../../event-bus';

	let defaultPlaceholder = 'Ange beskrivning';

	export default {

		props: {
			header: {},
			rowsjson: {},
			inputname: {},
			addevent: {},
			amountname: {
				default: 'amount',
			},
			labelname: {
				default: 'comment',
			},
			deleteconfirmation: {
				default: 'Säker?',
			},
			hasfileinput: {
				default: false,
			},
		},

		data: function() {
			return {
				rows: [],
				fileinputNumber: 0,
			}
		},

		created: function() {
			let rows = JSON.parse(this.rowsjson);

			rows.map(function(row) {
				if (! row.placeholder)
				{
					row.placeholder = defaultPlaceholder;
				}
			});

			this.rows = rows;
		},

		mounted: function() {
			EventBus.$on(this.addevent, this.addRow);
		},

		methods: {
			formatAmount: function(amount) {
				return Currency.format(amount);
			},

			deleteRow: function(rowToRemove)
			{
				if ( confirm(this.deleteconfirmation) )
				{
					this.rows = _.filter(this.rows, function(row) {
						return rowToRemove !== row;
					});
				}
			},

			addRow: function()
			{
				let row = {};
				this.fileinputNumber++;

				row[this.amountname] = '';
				row[this.labelname] = '';
				row.placeholder = defaultPlaceholder;

				if (this.hasfileinput)
				{
					row.attachment_fileinput = 'row_attachment_' + this.fileinputNumber;
				}

				this.rows.push(row);
			}
		},

		watch: {
			rows: {
				handler: function(newRows) {
					let sum = 0;
					let _this = this;

					newRows.map(function(row) {
						let rowAmount = parseFloat(row[_this.amountname]);

						if ( !isNaN(rowAmount) )
						{
							sum += rowAmount;
						}
					});

					EventBus.$emit(this.inputname + '-sum-updated', sum);
				},
				deep: true,
			}
		}
	}
</script>

<template>
	<div>
		<input type="hidden" :name="inputname" :value="JSON.stringify(rows)">
		<div class="project-data-row" v-if="rows.length > 0">
			<h3 class="title-xs">{{ header }}</h3>

			<div class="project-data-row">
				<div v-for="row in rows" class="project-data-cell flex flex-center invoice-job invoice-cell">
					<input type="text" :placeholder="row.placeholder" v-model="row[labelname]" style="width: 50%" v-focus>

					<span v-if="row.attachment_name">
						<a target="_blank" :href="row.attachment_url">{{ row.attachment_name }}</a>
					</span>

					<input v-if="row.attachment_fileinput" type="file" :name="row.attachment_fileinput">

					<div class="flex-right">
						<p>
							<input type="number" placeholder="Ange belopp" v-model="row[amountname]">
							<a href="" class="delete in-cell" v-on:click.prevent="deleteRow(row)"><img src="/img/delete.svg"></a>
						</p>
					</div>
				</div>

				<div v-if="rows.length === 0" class="project-data-cell flex flex-center invoice-job invoice-cell">Tomt!</div>
			</div>
		</div>
	</div>
</template>