<script>
	import distance from '../helpers/distance';
	import formatDistanceFromKm from '../helpers/formatDistanceFromKm';

	export default {

		data: function() {
			return {
				locations: [],
				settings: {},
				requiredProximity: 1,
				requiredAccuracy: 1,
				locationsUrl: null,
				checkinUrl: null,
				userLocation: null,
				showNoLocationMessage: false
			}
		},

		created: function() {
			this.settings = JSON.parse(document.getElementById('locations-settings').innerHTML);

			this.requiredProximity = this.settings.requiredProximity;
			this.requiredAccuracy = this.settings.requiredAccuracy;
			this.locationsUrl = this.settings.locationsUrl;
			this.checkinUrl = this.settings.checkinUrl;

			this.loadLocations();
			this.getUserLocation();

			self = this;
			setTimeout(function() {
				if ( self.userLocation === null )
				{
					self.showNoLocationMessage = true;
				}
			}, 2000);
		},

		methods: {
			loadLocations: function() {
				let locations = this.$http.get(this.locationsUrl).then(response => {
					console.debug('Locations loaded.');

					let locations = response.body;

					locations = locations.map(function(location) {
						location.proximity = null;
						location.proximityFormatted = null;
						location.checkinAllowed = false;

						return location;
					});

					this.locations = locations;

					this.updateLocations();

				}, response => {
					console.error('Error: Could not load locations.');
				});
			},

			getUserLocation: function() {
				if ( navigator.geolocation )
				{
					let self = this;
					navigator.geolocation.watchPosition(
						function(position) {
							console.debug('User location changed.');
							self.userLocation = position.coords;
							self.updateLocations();
						},
						function(error) {
							console.warn('Error (' + error.code + '): ' + error.message);
						},
						{
							enableHighAccuracy: true,
							timeout: 5000,
							maximumAge: 0
						}
					);
				}
			},

			updateLocations: function() {
				console.debug('Updating locations with proximity values.');

				let self = this;

				let locations = this.locations.map(function(location) {
					if ( self.userLocation )
					{
						location.proximity = distance(location.lat, location.lng, self.userLocation.latitude, self.userLocation.longitude);
						location.proximityFormatted = formatDistanceFromKm(location.proximity);
						location.checkinAllowed = self.userLocation.accuracy < self.requiredAccuracy && location.proximity * 1000 < self.requiredProximity;
					}

					return location;
				});

				locations.sort(function(locationA, locationB) {
					return locationA.proximity - locationB.proximity;
				});

				this.locations = locations;
			},

			doCheckin: function(e, location) {
				e.preventDefault();

				console.debug('Doing checkin.');

				let data = {
					_token: this.settings.csrfToken,
					location_id: location.id,
					lat: this.userLocation.latitude,
					lng: this.userLocation.longitude,
					accuracy: this.userLocation.accuracy
				};

				this.$http.post(this.checkinUrl, data).then(response => {
					if ( response.body.status === 'saved' )
					{
						console.debug('Checkin saved.');
					}
					else
					{
						console.error('Something went wrong when saving checkin.');
					}

					this.loadLocations();
				}, response => {
					console.error('Error: Could not save checkin.');
					console.debug(response.body);

					this.loadLocations();
				});
			}
		}
	}
</script>

<template>
	<div>
		<section class="content" v-if="userLocation === null && ! showNoLocationMessage">
			Söker position...
		</section>
		<section class="content" v-if="userLocation === null && showNoLocationMessage">
			Din position är okänd. Har du gett Oh My Tid tillgång till din position?
		</section>
		<section class="content" v-if="userLocation !== null && userLocation.accuracy > requiredAccuracy">
			Din position är inte exakt nog. Kontrollera att telefonens GPS är aktiverad.<br>
			<br>
			(Nuvarande noggrannhet är {{ Math.round(userLocation.accuracy) }} meter, men den behöver vara mindre än {{ requiredAccuracy }} meter.)
		</section>

		<section class="content checkin-location" v-if="userLocation != null" v-for="location in locations">
			<div class="checkin-action">
				<div v-if="location.checkinAllowed && location.already_checked_in === false">
					<a class="button button-large" v-on:click="doCheckin($event, location)" :href="checkinUrl">Checka in!</a>
				</div>
				<div v-if="location.already_checked_in">
					Incheckad
				</div>

				<div v-if="! location.checkinAllowed || location.already_checked_in">
					{{ location.proximityFormatted }}
				</div>
			</div>
			<div><a :href="'http://www.google.com/maps/place/' + location.lat + ',' + location.lng" target="_blank">{{ location.name }}</a></div>
			<div>{{ location.city }}</div>
		</section>
	</div>
</template>