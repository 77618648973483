<script>
	export default {

			methods: {
				subStateSelect(type)
				{
					this.$emit('project-type-selected', type);
				}
			},
	}
</script>

<template>
	<div class="sidebar-form">
		<button @click="subStateSelect('fixed_price')" id="project-fixed-price">Fast pris</button>
		<button @click="subStateSelect('retainer')" id="project-retainer">Retainer</button>
		<button @click="subStateSelect('hourly')" id="project-hourly">Löpande</button>
	</div>
</template>