<script>
	import {EventBus} from '../event-bus';

	export default {
		name: 'event-button',

		props: [
			'cssclass',
			'title',
			'event',
		],

		data: function() {
			return {}
		},

		methods: {
			clicked()
			{
				EventBus.$emit(this.event);
			}
		}
	}
</script>

<template>
	<a href="" :class="cssclass" v-on:click.prevent="clicked()">{{ title }}</a>
</template>