<script>
	export default {
		name: 'ajax-button',

		props: [
			'cssclass',
			'cssstyle',
			'title',
			'url',
			'method',
			'csrftoken',
			'confirmation',
			'redirecturl',
		],

		data: function() {
			return {}
		},

		methods: {
			request: function() {
				let method = this.method ? this.method.toLowerCase() : 'post';
				let data = { _token: this.csrftoken };

				if ( confirm(this.confirmation) )
				{
					if ( method === 'delete' )
					{
						this.$http[method](this.url, {body: data}).then(response => {
							this.handleResponse(response);
						}, response => {
							console.error('Something went wrong when doing request.');
						});
					}
					else if ( method === 'get' )
					{
						this.$http[method](this.url, {params: data}).then(response => {
							this.handleResponse(response);
						}, response => {
							console.error('Something went wrong when doing request.');
						});
					}
					else
					{
						this.$http[method](this.url, data).then(response => {
							this.handleResponse(response);
						}, response => {
							console.error('Something went wrong when doing request.');
						});
					}
				}
			},

			handleResponse: function(response)
			{
				if ( response.body.success && this.redirecturl )
				{
					window.location = this.redirecturl;
				}
			},
		}
	}
</script>

<template>
	<a href="" :class="cssclass" :style="cssstyle" v-on:click.prevent="request()">{{ title }}</a>
</template>