module.exports = function(distanceKm)
{
	if (distanceKm < 1)
	{
		return Math.round(distanceKm * 1000) + ' meter';
	}
	else if (distanceKm < 10)
	{
		return (Math.round(distanceKm * 10) / 10) + ' km';
	}
	else
	{
		return String(Math.round(distanceKm) / 10).replace('.', ',') + ' mil';
	}
};