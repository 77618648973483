window.jQuery = require('jquery');
window.$ = window.jQuery;

require('./delete-time-deviation').init();
require('./expense').init();

$(function() {
	$('.menu-trigger').click(function() {
		$('.main-nav').toggleClass('is-active');
	});
	if(navigator.userAgent.indexOf('Windows') != -1) {
		$('body').addClass('windows-pc');
	}
});

require('./vue-app')();