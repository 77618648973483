<script>
	import Fuse from 'fuse.js';
	import _ from 'underscore';
	import {EventBus} from '../../event-bus';
	import VueScrollTo from 'vue-scrollto';

	export default {
		name: 'edit-team-members',

		props: [
			'jsonurl',
			'currentteam',
			'inputname',
		],

		data: function() {
			return {
				teamMembers: [],
				searchTerm: '',
				users: null,
				matchingUsers: [],
				showAddUi: false,
			}
		},

		created: function() {
			this.teamMembers = JSON.parse(this.currentteam);
		},

		mounted: function() {
			let _this = this;

			EventBus.$on('show-add-team-member-ui', function() {
				_this.showAddUi = !_this.showAddUi;

				if ( _this.showAddUi )
				{
					setTimeout(function() {
						VueScrollTo.scrollTo(_this.$refs.addteammembers);
						_this.$refs.searchterm.focus();
					}, 10);
				}
			});
		},

		methods: {
			updateMatching()
			{
				if ( !(this.users instanceof Fuse) )
				{
					return;
				}

				let matchingUsers = this.users.search(this.searchTerm);
				this.matchingUsers = matchingUsers.slice(0, 10);
			},

			addMember(user)
			{
				let dupe = _.find(this.teamMembers, function(existingMember) {
					return existingMember.id === user.id;
				});

				if ( dupe === undefined )
				{
					this.teamMembers.push(user);
				}

				this.searchTerm = '';
				this.updateMatching();
				this.showAddUi = false;
			},

			removeMember(user)
			{
				console.log('Remove member');

				this.teamMembers = _.filter(this.teamMembers, function(existingMember) {
					return existingMember.id !== user.id;
				});

				this.searchTerm = '';
				this.updateMatching();
			}
		},

		watch: {
			searchTerm: function() {
				if ( this.users === null )
				{
					this.users = 'wait';

					this.$http.get(this.jsonurl).then(response => {
						console.debug('Users loaded.');

						this.users = new Fuse(response.body, {
							threshold: 0.1,
							matchAllTokens: true,
							tokenize: true,
							keys: ['name']
						});

						this.updateMatching();
					}, response => {
						console.error('Error: Could not load users.');
						this.users = null;
					});
				}

				this.updateMatching();
			}
		},
	}
</script>

<template>
	<div class="project-data-row">
		<input type="hidden" :name="inputname" :value="JSON.stringify(teamMembers)">

		<h3 class="title-xs">Team</h3>
		<div class="flex">

			<div class="project-data-cell no-border member-cell" v-for="member in teamMembers">
				<img :src="member.avatar" class="member-avatar">
				<p class="member-name">{{ member.name }}</p>
				<a href="" class="button member-remove" v-on:click.prevent="removeMember(member)">-</a>
			</div>

			<div v-if="teamMembers.length ==0">
				Här var det tomt!
			</div>

		</div>

		<div v-if="showAddUi" ref="addteammembers">
			<h3 class="title-xs">Lägg till medlemmar</h3>
			<div class="select-team">
				<input ref="searchterm" type="text" v-model="searchTerm" placeholder="Sök person" id="teammember-search">

				<div style="min-height: 200px;">
					<div class="select-team-list align-left" v-if="matchingUsers.length > 0">
						<ul>
							<li class="clickable" v-for="user in matchingUsers" v-on:click.prevent="addMember(user)">
								{{ user.name }}
							</li>
						</ul>
					</div>

					<div v-if="matchingUsers.length == 0 && searchTerm != '' && this.users !== null && this.users !== 'wait'">
						Hittar tyvärr ingen användare som matchar angiven sökfras.
					</div>
				</div>
			</div>
		</div>
	</div>
</template>