<script>
	import moment from 'moment';
	export default {

		props: [
			'form',
		],

		methods: {
			empty(input)
			{
				return input === '' || input === 0 || input === null;
			},

			getFixedPriceHourlyPrice()
			{
				if ( this.form.projectType !== 'fixed_price' || isNaN(this.form.hours) || !(this.form.hours > 0) || isNaN(this.form.revenue) )
				{
					return '-';
				}

				return Math.round(this.form.revenue / this.form.hours);
			},

			getMonthlyHours()
			{
				if ( this.form.projectType === 'fixed_price' )
				{
					return this.form.hours !== null ? this.form.hours : '-';
				}

				if ( isNaN(this.form.hourlyPrice) || !(this.form.hourlyPrice > 0) || isNaN(this.form.revenue) )
				{

					return '-';
				}

				return Math.round(this.form.revenue / this.form.hourlyPrice);
			},

			getStartDate()
			{
				return !this.empty(this.form.startDate) ? moment(this.form.startDate).format('Y.MM.DD') : '-';
			},

			getEndDate()
			{
				return !this.empty(this.form.endDate) ? moment(this.form.endDate).format('Y.MM.DD') : '-';
			}
		},
	}
</script>

<template>
	<article class="card">
		<a href="javascript:;" class="settings">Settings</a>

		<div class="row" :class="{'is-empty' : empty(form.projectname)}">
			<h3 class="card-title">
				{{ form.projectName }}
			</h3>
			<h4 class="card-title">
				{{ form.customerName }}
			</h4>
		</div>

		<!-- FIXED -->
		<div v-if="form.projectType == 'fixed_price'">
			<div class="row" :class="{'is-empty': empty(form.revenue) || empty(form.hours)}">
				<h3 class="label">
					{{ form.revenue }} kr / {{ form.hours }} h
				</h3>
				<span class="sub">Fastpris</span>
			</div>

			<div class="row" :class="{'is-empty': empty(form.revenue) || empty(form.hours)}">
				<h3 class="label">{{ getFixedPriceHourlyPrice() }} kr</h3>
				<span class="sub">Timpris enligt ovan</span>
			</div>
		</div>
		<!-- /FIXED -->

		<!-- HOURLY -->
		<div v-if="form.projectType == 'hourly'">
			<div class="row" :class="{'is-empty': empty(form.hourlyPrice)}">
				<h3 class="label">{{ form.hourlyPrice }} kr</h3>
				<span class="sub">Timpris</span>
			</div>

			<div class="row" :class="{'is-empty': empty(form.revenue)}">
				<h3 class="label">
					{{ form.revenue }} kr
				</h3>
				<span class="sub">Uppskattad intäkt/månad</span>
			</div>

			<div class="row" :class="{'is-empty': getMonthlyHours() == '-'}">
				<h3 class="label">{{ getMonthlyHours() }} h/månad</h3>
				<span class="sub">Tidsuppskattning enligt ovan</span>
			</div>
		</div>
		<!-- /HOURLY -->

		<!-- RETAINER -->
		<div v-if="form.projectType == 'retainer'">
			<div class="row" :class="{'is-empty': empty(form.revenue)}">
				<h3 class="label">
					{{ form.revenue }} kr
				</h3>
				<span class="sub">Intäkt/månad</span>
			</div>

			<div class="row" :class="{'is-empty': empty(form.hourlyPrice)}">
				<h3 class="label">{{ form.hourlyPrice }} kr</h3>
				<span class="sub">Timpris</span>
			</div>
		</div>
		<!-- /RETAINER -->

		<div class="row" :class="{'is-empty': empty(form.startDate)}">
			<h3 class="label">{{ getStartDate() }}</h3>
			<span class="sub">Startdatum</span>
		</div>

		<div v-if="form.projectType == 'fixed_price' || ! empty(form.endDate)" class="row summary" :class="{'is-empty': empty(form.endDate)}">
			<h3 class="label">{{ getEndDate() }}</h3>
			<span class="sub">Slutdatum</span>
		</div>

		<div class="row members no-border"
			 :class="{'is-empty': form.projectManager == null && form.invoiceManager == null}">
			<div>
				<div v-if="form.projectManager != null" class="team-member role-manager" :title="form.projectManager.name"
					 v-bind:style="{ backgroundImage: form.projectManager.avatar != '' ? 'url(' + form.projectManager.avatar + ')' : 'none' }">
				</div>

				<div v-if="form.invoiceManager != null" class="team-member role-invoice" :title="form.invoiceManager.name"
					 v-bind:style="{ backgroundImage: form.invoiceManager.avatar != '' ? 'url(' + form.invoiceManager.avatar + ')' : 'none' }">
				</div>
			</div>
			<span class="sub">Team</span>
		</div>
	</article>
</template>