module.exports = {
	init: function()
	{
		var $ = require('jquery');

		// When the user want to replace the attachment when editing an expense
		$('.js-replace-attachment').click(function(e)
		{
			e.preventDefault();
			e.stopPropagation();

			$(this).closest('.js-old-attachment-holder').remove();
			$('#attachment').show();
		})
	}
};